import cn from 'classnames'

import { CurrencyIcon } from '../../../components'
import { AccountBalanceOut } from 'mmfintech-commons-types'
import { AccountButtonStyled } from './SelectedAccount.styled'

import { tr, formatMoney } from 'mmfintech-commons'

import CheckMark from '../../../images/icons/password-terms-check.svg?react'

interface SelectedAccountProps {
  account: AccountBalanceOut
  isSelected: boolean
  error?: string
  setAccount: (account: AccountBalanceOut) => void
}

export const SelectedAccount = ({ account, isSelected, setAccount, error }: SelectedAccountProps) => {
  const { balance, currencyCode, name } = account || {}
  return (
    <AccountButtonStyled
      onClick={() => setAccount(account)}
      className={cn({
        'no-shadow': true,
        // static: true,
        error: error?.length,
        'no-border': false,
        'is-selected': isSelected
      })}>
      <span className='account-info'>
        <span className='icon'>
          <CurrencyIcon currency={currencyCode} width='26px' height='26px' />
        </span>
        <span className='content'>
          <span className='balance'>
            <b>{tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.BALANCE_LABEL', 'Balance')}:</b>{' '}
            {formatMoney(balance, currencyCode)}
          </span>
          <span>{name}</span>
        </span>
      </span>

      {isSelected && <CheckMark />}
      {/* {!staticDisplay && <span className='chevron'>{visible ? <ChevronUp /> : <ChevronDown />}</span>} */}
    </AccountButtonStyled>
  )
}
