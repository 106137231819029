import { toast } from 'react-hot-toast'

import { Button } from 'mmfintech-portal-commons'
import { QRCodeSVG } from 'qrcode.react'
import { QrCodeWrapper } from './PixPreview.styled'

import { copyTextToClipboard, tr } from 'mmfintech-commons'

import PixLogo from '../../../../images/icons/pix-logo.svg?react'

export const PixPreview = ({ address }) => {
  return (
    <QrCodeWrapper>
      <p className='text-center'>
        <PixLogo />
      </p>
      <p className='text-center'>
        {tr(
          'FRONTEND.DEPOSIT.RESULT.QR_CODE',
          'Open the app with your registered PIX key, choose Pay with Pix and scan the QR Code or copy and paste the code.'
        )}
      </p>
      <p className='text-center code'>
        <QRCodeSVG value={address} size={256} />
      </p>
      <p className='text-center'>
        <Button
          type='button'
          text={tr('FRONTEND.DEPOSIT.RESULT.COPY_CODE', 'Copy code')}
          onClick={() => {
            copyTextToClipboard(address)
            const message = tr('FRONTEND.DEPOSIT.RESULT.QR_CODE_COPIED', 'Code is copied to clipboard')
            toast.remove()
            toast.success(message)
          }}
        />
      </p>
    </QrCodeWrapper>
  )
}
