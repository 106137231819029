import { tr } from 'mmfintech-commons'

import { Button } from 'mmfintech-portal-commons'
import { FilterControlsStyle } from './FilterControls.style'

import FilterIcon from '../../images/expenses/filter-icon.svg?react'
import ResetFilterIcon from '../../images/expenses/reset-filters.svg?react'

export const FilterControls = ({
  validFieldsCount,
  filter,
  handleShowFilters,
  showFilters,
  resetProps = [],
  isAlwaysOpen = false
}) => {
  return (
    <FilterControlsStyle>
      {validFieldsCount > 0 && (
        <Button
          className='sticky filters-button-container'
          text={tr('FRONTEND.BUTTONS.RESET', 'Reset')}
          icon={<ResetFilterIcon />}
          data-test='button-add-report'
          onClick={() => filter.reset([...resetProps, 'counterpartyId', 'foreignId'])}
        />
      )}
      {!isAlwaysOpen && (
        <Button
          color='round-secondary'
          icon={<FilterIcon />}
          iconPosition='left'
          onClick={handleShowFilters}
          className='filters-button-container toggle-filter'
          text={
            <div className='filters-button'>
              <span>
                {showFilters ? tr('FRONTEND.BUTTONS.HIDE', 'Hide') : tr('FRONTEND.BUTTONS.SHOW', 'Show')}{' '}
                {tr('FRONTEND.TRANSACTIONS.FILTER.FILTERS_BUTTON', 'Filters')}
              </span>
              {validFieldsCount > 0 && <span className='applied-filters'>{validFieldsCount}</span>}
            </div>
          }
        />
      )}
    </FilterControlsStyle>
  )
}
