import { shallowEqual, useSelector } from 'react-redux'

import { ErrorDisplay } from 'mmfintech-portal-commons'
import { BannerWrapper, Centered, TextBody } from './OnboardingBanner.styled'

import { tr } from 'mmfintech-commons'
import { useOnboarding } from '../../../hooks'

import { MerchantAccountTypeEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'

import warningImage from '../../../images/icons/warning-triangle.png'

import VerifyArrowIcon from '../../../images/icons/verify-arrow.svg?react'

export const OnboardingBanner = ({ className }) => {
  const { merchant, applicationFeeInitiateError } = useSelector(
    ({ user: { merchant }, otp: { applicationFeeInitiateError } }: any) => ({
      merchant,
      applicationFeeInitiateError
    }),
    shallowEqual
  )

  const { accountType, onboardingStatus } = merchant || {}

  const { showError, payAccountSetupFee, shouldPayAccountSetupFee, startOrContinueOnboarding } = useOnboarding()

  const Banner = ({ children, clickHandler = undefined }) => {
    return (
      <BannerWrapper className={className} onClick={clickHandler}>
        <Centered className='mr-2'>
          <img src={warningImage} alt='warning-img' />
        </Centered>
        {children}
        <Centered className='ml-2'>{clickHandler !== 'none' && <VerifyArrowIcon />}</Centered>
      </BannerWrapper>
    )
  }

  if (accountType === MerchantAccountTypeEnum.PROSPECT) {
    if (
      onboardingStatus === OnboardingStatusEnum.NOT_STARTED ||
      onboardingStatus === OnboardingStatusEnum.IN_PROGRESS ||
      onboardingStatus === OnboardingStatusEnum.REJECTED_RETRY
    ) {
      return (
        <Banner
          clickHandler={() => {
            startOrContinueOnboarding()
          }}>
          <TextBody>
            <div className='onboarding-resume-header'>
              {tr('FRONTEND.ONBOARDING.RESUME_ALERT_TITLE', 'Complete your account')}
            </div>
            {tr(
              'FRONTEND.ONBOARDING.RESUME_ALERT_TEXT',
              'Verify to get access to all features and increased payment limits.'
            )}
          </TextBody>
        </Banner>
      )
    }

    if (onboardingStatus === OnboardingStatusEnum.WAITING_ADDITIONAL_DOCUMENTS) {
      return (
        <Banner
          clickHandler={() => {
            startOrContinueOnboarding()
          }}>
          <TextBody>
            <div className='onboarding-resume-header'>
              {tr('FRONTEND.ONBOARDING.ADDITIONAL_DOCUMENTS.TITLE', 'Complete your account')}
            </div>
            {tr(
              'FRONTEND.ONBOARDING.ADDITIONAL_DOCUMENTS.MESSAGE',
              'Complete the final step of your account activation - Additional Documents'
            )}
          </TextBody>
        </Banner>
      )
    }

    if (onboardingStatus === OnboardingStatusEnum.WAITING_QUESTIONNAIRE) {
      return (
        <Banner
          clickHandler={() => {
            startOrContinueOnboarding()
          }}>
          <TextBody>
            <div className='onboarding-resume-header'>
              {tr('FRONTEND.QUESTIONNAIRE.KYB.TITLE', 'KYB Questionnaire')}
            </div>
            {tr(
              'FRONTEND.ONBOARDING.QUESTIONNAIRE.MESSAGE',
              'Complete the questionnaire in order to proceed with the account onboarding'
            )}
          </TextBody>
        </Banner>
      )
    }

    if (
      onboardingStatus === OnboardingStatusEnum.UNDER_REVIEW ||
      onboardingStatus === OnboardingStatusEnum.WAITING_COMPLIANCE_APPROVAL ||
      onboardingStatus === OnboardingStatusEnum.WAITING_OPS_APPROVAL
    ) {
      return (
        <Banner>
          <TextBody className='centered'>
            <div className='onboarding-resume-header'>
              {tr('FRONTEND.DASHBOARD.SUMSUB.UNDER_REVIEW_MESSAGE', 'Your account verification is under review.')}
            </div>
          </TextBody>
        </Banner>
      )
    }

    if (onboardingStatus === OnboardingStatusEnum.REJECTED_FINAL) {
      return (
        <Banner>
          <TextBody className='centered'>
            <div className='onboarding-resume-header'>
              {tr(
                'FRONTEND.DASHBOARD.SUMSUB.REJECTED_MESSAGE',
                'Please contact Customer Service to get support on your account verification.'
              )}
            </div>
          </TextBody>
        </Banner>
      )
    }

    if (shouldPayAccountSetupFee()) {
      return (
        <Banner clickHandler={payAccountSetupFee}>
          <TextBody>
            {showError && <ErrorDisplay error={applicationFeeInitiateError} />}
            <div className='onboarding-resume-header'>
              {tr('FRONTEND.ONBOARDING.SETUP_FEE.TITLE', 'Complete your account')}
            </div>
            {tr(
              'FRONTEND.ONBOARDING.SETUP_FEE.MESSAGE',
              'You need to pay your application fee to enable your primary account.'
            )}
          </TextBody>
        </Banner>
      )
    }
  }

  //commenting out the banner as per request from A. Rusev
  // if (shouldRequestIban()) {
  //   return (
  //     <Banner>
  //       <Centered className='mr-2'>
  //         <WarningTriangleYellow />
  //       </Centered>
  //       <TextBody>
  //         <div className='onboarding-resume-header'>
  //           {tr('FRONTEND.ONBOARDING.REQUEST_IBAN.TITLE', 'Complete your account')}
  //         </div>
  //         {tr('FRONTEND.ONBOARDING.REQUEST_IBAN.MESSAGE', 'You can get your own dedicated IBAN.')}
  //       </TextBody>
  //       <Centered className='ml-2'>
  //         <LargeButton
  //           data-test='request-iban'
  //           onClick={requestIban}
  //           text={tr('FRONTEND.ONBOARDING.REQUEST_IBAN.REQUEST_BUTTON', 'Request now')}
  //         />
  //       </Centered>
  //     </Banner>
  //   )
  // }

  return null
}
