import { useHistory } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'

import SpendingLimitBarUser from '../../../components/SpendingBar/SpendingLimitBarUser'

import { Button } from 'mmfintech-portal-commons'
import { DashboardTitle } from '../Dashboard.styled'
import { AccountsOverview } from '../../account/AccountsOverview'
import { TransactionsSection } from '../TransactionsSection'
import { StaffDashboardButtons, StaffDashboardContainer, StaffDashboardWrapper } from './StaffDashboard.styled'

import { tr } from 'mmfintech-commons'
import { actions, paths } from 'mmfintech-backend-api'
import { useHasWritePermissions } from '../../../hooks'

import SendIcon from '../../../images/icons/send.svg?react'
import DepositIcon from '../../../images/icons/deposit.svg?react'
import ScanAndPayIcon from '../../../images/icons/scan-pay-icon.svg?react'

export const StaffDashboard = () => {
  const { merchant } = useSelector(
    ({ user: { merchant } }: any) => ({
      merchant
    }),
    shallowEqual
  )

  const { capabilities } = merchant || {}
  const { enablePortalWalletCheckout } = capabilities || {}

  const hasAnyPermissions = useHasWritePermissions()
  const history = useHistory()

  const handleDepositClick = () => {
    actions.routing.setDepositOrigin('')
    history.push(paths.banking.deposit())
  }

  const handleSendMoneyClick = () => {
    actions.routing.setWithdrawOrigin('')
    history.push(paths.banking.send())
  }

  return (
    <StaffDashboardWrapper data-test='staff-dashboard'>
      <DashboardTitle className='title'>{tr('FRONTEND.DASHBOARD.TITLE', 'Overview')}</DashboardTitle>

      <StaffDashboardContainer>
        {hasAnyPermissions && <SpendingLimitBarUser />}

        <StaffDashboardButtons>
          {hasAnyPermissions && (
            <>
              <Button
                type='button'
                color='secondary'
                icon={<DepositIcon />}
                text={tr('FRONTEND.DASHBOARD.BUTTONS.DEPOSIT', 'Deposit')}
                onClick={handleDepositClick}
                data-test='deposit-button'
                data-for='button-tooltip'
              />

              <Button
                type='button'
                color='secondary'
                icon={<SendIcon />}
                text={tr('FRONTEND.DASHBOARD.BUTTONS.SEND', 'Send')}
                onClick={handleSendMoneyClick}
                data-test='send-money-button'
              />

              {enablePortalWalletCheckout && (
                <Button
                  type='button'
                  color='secondary'
                  icon={<ScanAndPayIcon />}
                  text={tr('FRONTEND.DASHBOARD.BUTTONS.SCAN_AND_PAY', 'Scan & Pay')}
                  onClick={() => history.push(paths.scanAndPay())}
                  data-test='scan-and-pay-button'
                />
              )}
            </>
          )}
        </StaffDashboardButtons>
      </StaffDashboardContainer>

      <AccountsOverview />
      <TransactionsSection />
    </StaffDashboardWrapper>
  )
}
