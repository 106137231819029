import { isValidElement, useContext, useEffect, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import cn from 'classnames'
import { isOwnerOrAdministrator } from 'mmfintech-backend-api'
import {
  GlobalContext,
  tr,
  useDropDownMenu,
  formatMoney,
  isValidArray,
  isValidString,
  sortObjectArray
} from 'mmfintech-commons'

import { Button } from 'mmfintech-portal-commons'
import { ThreeDots } from 'react-loader-spinner'
import { CurrencyIcon } from '../CurrencyIcon'
import { AccountCreateModal } from '../../views/account/AccountCreateModal'
import { ISelectAccountProps } from './SelectAccount.types'
import {
  AccountButtonStyled,
  AccountListStyled,
  AccountStyled,
  AddButtonWrapper,
  SelectAccountError,
  SelectAccountLabel,
  SelectAccountStyled,
  SelectAccountWrapper
} from './SelectAccount.styled'

import ChevronDown from '../../images/icons/chevron-down-black.svg?react'
import ChevronUp from '../../images/icons/chevron-up-black.svg?react'

export const SelectAccount = (props: ISelectAccountProps) => {
  const {
    // if set, static account display will be presented
    staticDisplay = false,
    // list of accounts to select from
    accounts,
    // error message (if needed)
    error,
    // label above the selection box
    label,
    // if set an "Add New Account" button will be added at the end of the list
    showAddButton = false,
    // if set, "All Account" option will be added at the beginning of the list
    showAllAccounts = false,
    // selected account
    selectedAccount,
    // selected account setter
    setSelectedAccount,
    // list of supported currencies for the add new account screen
    filteredCurrencies,
    noShadow = false,
    hasExchangeAssets = true,
    className,
    // shows account id next to name
    showId = false,
    // hides border
    noBorder = false,
    loading,
    labelStyles = {},
    extraSection = null,
    onAddAccount
  } = props

  const { customerRole } = useSelector(
    ({ user: { customerRole } }: any) => ({
      customerRole
    }),
    shallowEqual
  )

  const [filteredAccounts, setFilteredAccounts] = useState([])
  const [preselectAccountId, setPreselectAccountId] = useState(0)

  const { modalShow } = useContext(GlobalContext)

  const { visible, hideDropDown, toggleDropDown } = useDropDownMenu()

  const toggle = () => !staticDisplay && !loading && toggleDropDown()

  const handleSelect = (account: unknown = null) => {
    hideDropDown()
    setSelectedAccount(account)
  }

  const handleCreateSuccess = (currency: string, accountId: unknown) => {
    if (typeof onAddAccount === 'function') {
      onAddAccount(currency, Number(accountId))
    } else {
      setPreselectAccountId(Number(accountId))
    }
  }

  const handleNewAccountClick = () => {
    modalShow({
      options: { closeOnClickOutside: false, size: 'auto', overflow: 'none' },
      content: <AccountCreateModal filteredCurrencies={filteredCurrencies} onSuccess={handleCreateSuccess} />
    })
  }

  // @ts-ignore
  const { balance, currencyCode, name, id } = selectedAccount || {}

  useEffect(() => {
    const list = Array.isArray(accounts) ? accounts : []
    setFilteredAccounts(list)
    // eslint-disable-next-line
  }, [accounts])

  useEffect(() => {
    if (Array.isArray(accounts) && preselectAccountId) {
      const find = accounts.find(account => account.id === preselectAccountId)
      setPreselectAccountId(0)
      handleSelect(find)
    }
  }, [accounts])

  return (
    <SelectAccountWrapper className={cn('select-account-wrapper', className)} data-test='account-dropdown'>
      {label && <SelectAccountLabel style={labelStyles}>{label}</SelectAccountLabel>}
      <SelectAccountStyled>
        <AccountButtonStyled
          className={cn({ 'no-shadow': noShadow, static: staticDisplay, error: error?.length, 'no-border': noBorder })}
          onClick={toggle}>
          {loading ? (
            <span className='loading'>
              <ThreeDots height='1.4rem' color='#ff3131' />
            </span>
          ) : selectedAccount ? (
            <span className='account-info'>
              <span className='icon'>
                <CurrencyIcon currency={currencyCode} width='26px' height='26px' />
              </span>
              <span className='content'>
                {id ? (
                  <span className='balance'>
                    <b>{tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.BALANCE_LABEL', 'Balance')}:</b>{' '}
                    {formatMoney(balance, currencyCode)}
                  </span>
                ) : null}
                {name && (
                  <span>
                    {name} {showId && id ? ` - ID: ${id}` : ''}
                  </span>
                )}
              </span>
            </span>
          ) : (
            <span className='no-account'>
              {showAllAccounts
                ? tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.ALL_ACCOUNTS', 'All Accounts')
                : tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.PLACEHOLDER', 'Select Account')}
            </span>
          )}
          {!staticDisplay && <span className='chevron'>{visible ? <ChevronUp /> : <ChevronDown />}</span>}
        </AccountButtonStyled>

        {!staticDisplay && (
          <AccountListStyled opened={visible} data-test='accounts-menu-content'>
            {showAllAccounts && (
              <AccountStyled data-test='all-accounts-menu-item' onClick={() => handleSelect()}>
                <span className='icon' />
                <span className='content'>
                  <span className='balance'>
                    {tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.ALL_ACCOUNTS', 'All Accounts')}
                  </span>
                </span>
              </AccountStyled>
            )}
            {!isValidArray(filteredAccounts) && (
              <AccountStyled data-test='all-accounts-menu-item' className='disabled'>
                <span className='icon'>
                  <span className='icon-all'>N/A</span>
                </span>
                <span className='content'>
                  <span className='balance'>
                    <b>
                      {hasExchangeAssets
                        ? tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.NO_ACCOUNTS', 'No Eligible Accounts')
                        : tr(
                            'FRONTEND.COMPONENTS.SELECT_ACCOUNT.CANNOT_EXCHANGE',
                            'Currently this asset cannot be exchanged'
                          )}
                    </b>
                  </span>
                </span>
              </AccountStyled>
            )}
            {sortObjectArray(filteredAccounts, 'currencyCode').map(account => {
              const { id, currencyCode, balance, name: accName } = account

              return (
                <AccountStyled
                  key={id}
                  onClick={() => handleSelect(account)}
                  data-test='account-menu-item'
                  className='option'>
                  <span className='icon'>
                    <CurrencyIcon currency={currencyCode} width='26px' height='26px' />
                  </span>
                  <span className='content'>
                    <span className='balance'>
                      {tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.BALANCE_LABEL', 'Balance')}:{' '}
                      {formatMoney(balance, currencyCode)}
                    </span>
                    <span>{accName}</span>
                  </span>
                </AccountStyled>
              )
            })}
            {showAddButton && isOwnerOrAdministrator(customerRole) && (
              <AddButtonWrapper>
                <Button
                  type='button'
                  color='round-secondary'
                  text={tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.ADD_BUTTON', 'Add New Account')}
                  onClick={handleNewAccountClick}
                />
              </AddButtonWrapper>
            )}
            {isValidElement(extraSection) ? extraSection : null}
          </AccountListStyled>
        )}
      </SelectAccountStyled>

      {isValidString(error) && <SelectAccountError>{error}</SelectAccountError>}
    </SelectAccountWrapper>
  )
}
