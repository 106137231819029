import { CurrencyIcon } from '../CurrencyIcon'
import { ICurrencyBadgeProps } from './CurrencyBadge.types'
import { CurrencyBadgeWrapper } from './CurrencyBadge.styled'

import CheckMarkIcon from '../../images/icons/password-terms-check.svg?react'

export const CurrencyBadge = ({ currencyCode, isSelected, onClick }: ICurrencyBadgeProps) => {
  return (
    <CurrencyBadgeWrapper isSelected={isSelected} onClick={onClick}>
      <div>
        <CurrencyIcon currency={currencyCode} circle width='2.2rem' height='2.2rem' />
      </div>
      <span data-test='currency' className='currency-name'>
        {currencyCode}
      </span>
      {isSelected && <CheckMarkIcon />}
    </CurrencyBadgeWrapper>
  )
}
