import React from 'react'
import ReactDOM from 'react-dom'
import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { enableMapSet, enableES5 } from 'immer'

import { configuration, mixedReducers, middlewares, apiConfig } from 'mmfintech-backend-api'

import './i18n'

import 'react-phone-input-2/lib/style.css'
import 'react-datepicker/dist/react-datepicker.css'
import './index.css'

import App from './App'
import { Analytics } from 'mmfintech-commons'
import { BrowserRouter } from 'react-router-dom'

import { pdfjs } from 'react-pdf'
import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

enableMapSet()
enableES5()

export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
  configureStore({
    reducer: mixedReducers,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middlewares.apiMiddleware).concat(thunk),
    ...options
  })

export const store = createStore()

export type RootState = ReturnType<typeof store.getState>
export type AppStore = ReturnType<typeof createStore>

export type AppDispatch = AppStore['dispatch']

const d = document

if (configuration.isProduction()) {
  const s1 = d.createElement('script')
  s1.type = 'text/javascript'
  s1.id = 'ze-snippet'
  s1.src = 'https://static.zdassets.com/ekr/snippet.js?key=87682a91-0dab-40a5-9abe-c081c935f759'
  d.getElementsByTagName('body')[0].appendChild(s1)

  const s2 = d.createElement('script')
  s2.type = 'text/javascript'
  s2.src = 'https://l.getsitecontrol.com/g4d2585w.js'
  s2.async = true
  d.getElementsByTagName('head')[0].appendChild(s2)
}

ReactDOM.render(
  <React.StrictMode>
    <Analytics
      enabled={configuration.isProduction()}
      codes={[apiConfig.VITE_GTM_CODE as string, apiConfig.VITE_GA_CODE as string]}
    />
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('kingdom-app')
)
