import styled from 'styled-components'

import IconClose from '../../../images/icons/icon-close.svg'

export const SurveyForm = styled.div`
  @keyframes enter {
    from {
      bottom: -450px;
    }
    to {
      bottom: 0;
    }
  }
  @keyframes exit {
    from {
      bottom: 0;
    }
    to {
      bottom: -450px;
    }
  }

  display: flex;
  flex-direction: column;

  &.not-visible {
    animation: exit 2.1s normal;
    animation-delay: 2s;
  }
  &.visible {
    animation: enter 2.1s normal;
  }

  position: fixed;
  bottom: 0;
  right: 0;

  padding: 2rem;
  border-radius: 0.8rem;

  background-color: white;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);
  max-width: 30rem;

  margin: 3rem;
  font-weight: 400;

  h2 {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.9rem;
    text-align: center;
  }

  .success-subtitle {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.7rem;
    text-align: center;
  }

  .survey-success {
    align-self: center;
  }

  h3 {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.7rem;
    text-align: center;
  }

  span {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  .additional {
    color: #595959;
    font-style: italic;
    font-size: 1rem;
    line-height: 1.2rem;
  }

  .input-wrapper {
    .custom-textarea::placeholder {
      color: #85a0ad;
      font-style: italic;
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  }

  svg {
    cursor: pointer;
  }

  .close-survey-icon {
    top: 0;
    right: 0;

    :hover {
      //prettier-ignore
      background: url("${IconClose}") center center no-repeat;
    }
  }
`
