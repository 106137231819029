import styled from 'styled-components'

import ArrowIcon from '../../../../images/icons/arrow-right-small-grey.svg'
import HoverIcon from '../../../../images/icons/arrow-right-small-black.svg'

export const SendMoneyButtonWrapper = styled.div`
  height: 27rem;
  width: 100%;
  max-width: 28rem;

  margin: 0 auto;

  @media (min-width: 640px) {
    margin: 0;

    :hover {
      .button {
        transform: translateY(-0.5rem);
      }
    }
  }
`

export const SendMoneyButtonContainer = styled.div`
  height: 27rem;
  width: 100%;
  max-width: 28rem;
  position: relative;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  background: #ffffff;
  box-shadow: 0 0 2.5rem rgba(46, 53, 56, 0.1);

  color: #000000;
  font-family: inherit;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  padding: 2.2rem 2rem;

  &:hover {
    transition: all 0.5s;
  }
  transition: all 0.5s;

  @media (max-width: 980px) {
    transition: none;
  }

  .arrow {
    width: 25px;
    height: 15px;
    position: absolute;
    top: 3rem;
    right: 3rem;
    //prettier-ignore
    background: url("${ArrowIcon}") center no-repeat;
  }

  &:hover .arrow {
    //prettier-ignore
    background: url("${HoverIcon}") center no-repeat;
  }

  .description {
    color: #000000;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7rem;
    margin-top: 0.5rem;
  }
`
