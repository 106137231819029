import { Button } from 'mmfintech-portal-commons'
import { useHistory } from 'react-router-dom'
import { paths } from 'mmfintech-backend-api'

import { RedeemVoucherSuccessContainer } from './RedeemVoucher.styled'

import SuccessIcon from '../../../images/icons/payment-success-icon.svg?react'

export const RedeemVoucherSuccess = () => {
  const history = useHistory()
  const handleClick = () => {
    history.push(paths.more.cashVoucher('list'))
  }
  return (
    <RedeemVoucherSuccessContainer>
      <SuccessIcon />
      <div>Voucher Redeemed Successfully!</div>
      <Button text='Back to my vouchers' className='back-to-vouchers-button' onClick={handleClick} />
    </RedeemVoucherSuccessContainer>
  )
}
