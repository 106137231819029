import { tr } from 'mmfintech-commons'
import ReactTooltip from 'react-tooltip'

import { BuyVoucherAmountLabelWrapper } from './BuyVouchersTab.styled'

import InfoIcon from '../../../images/icons/info.svg?react'

type BuyVoucherAmountLabelProps = {
  selectedType: 'single' | 'bulk'
  currencyCode?: string
}

const singleTipContent = currencyCode => `<strong>Voucher Value in ${currencyCode} </strong></br> </br>
<strong>Kingdom Cash</strong> vouchers are only denominated in Euro or United States Dollar. Type in the amount you desire, or choose one of the preselected options for your convenience.`

const bulkTooltipContent = currencyCode => `<strong>Voucher Value in ${currencyCode} </strong></br> </br>
<strong>Kingdom Cash</strong> vouchers are only denominated in Euro or United States Dollar. Type in the number of vouchers you wish to purchase with this transaction`

export const BuyVoucherAmountLabel = ({ selectedType, currencyCode }: BuyVoucherAmountLabelProps) => {
  return (
    <BuyVoucherAmountLabelWrapper>
      <span>
        {selectedType === 'single'
          ? tr('FRONTEND.KINGDOM_CASH.VOUCHER_INPUT_LABEL', 'Voucher value')
          : 'Number of Vouchers'}
      </span>
      <span
        data-for='info-tooltip'
        data-tip={selectedType === 'single' ? singleTipContent(currencyCode) : bulkTooltipContent(currencyCode)}>
        <InfoIcon width={14} height={14} />
      </span>
      <ReactTooltip
        id='info-tooltip'
        place='bottom'
        html
        type='dark'
        arrowColor='transparent'
        className='tooltip'
        overridePosition={({ left, top }, _currentEvent, _currentTarget, node) => {
          left = left + node.clientWidth / 2
          return { top, left }
        }}
      />
    </BuyVoucherAmountLabelWrapper>
  )
}
