import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { GlobalContext } from 'mmfintech-commons'
import { ErrorDisplay, Spinner } from 'mmfintech-portal-commons'
import { NewsImage, NewsModalWrapper } from './NewsModal.styled'
import { NewsButtonsContainer, NewsContentSection } from './elements'

import { actions, endpoints, request, useUserState } from 'mmfintech-backend-api'

import { PortalNewsResponse } from 'mmfintech-commons-types'

import NewsFallbackIcon from '../../../images/icons/news-fallback-icon.svg?react'

export const NewsModal: React.FC = () => {
  const { modalHide } = useContext(GlobalContext)
  const dispatch = useDispatch()
  const { availableNews, markAsSeenError } = useUserState()
  const [activeNewsIndex, setActiveNewsIndex] = useState(0)
  const [seenNewsIds, setSeenNewsIds] = useState(new Set<number>())
  const [currentImage, setCurrentImage] = useState('')
  const [loading, setLoading] = useState(true)

  const currentNews: PortalNewsResponse = availableNews?.[activeNewsIndex] || {}

  const { title, content, imagePath, id: newsId } = currentNews

  const markNewsAsSeen = (newsId: number) => {
    if (!seenNewsIds.has(newsId)) {
      dispatch(actions.merchant.markAsSeenNews(newsId))
      setSeenNewsIds(prev => new Set(prev).add(newsId))
    }
  }

  const handleTransition = (index: number): void => {
    if (index >= 0 && index < availableNews.length) {
      markNewsAsSeen(availableNews[index].id)
      setActiveNewsIndex(index)
    } else if (index === availableNews.length) {
      modalHide()
    }
  }

  useEffect(() => {
    // Mark the first news as seen when the component is mounted
    if (availableNews.length > 0) {
      markNewsAsSeen(availableNews[0].id)
    }
    // Mark the current news as seen when unmounting
    return () => {
      markNewsAsSeen(availableNews[activeNewsIndex].id)
    }
  }, [availableNews, activeNewsIndex])

  useEffect(() => {
    let didCancel = false

    const createObjectURL = async () => {
      try {
        if (!newsId) return
        const response = await request({
          endpoint: endpoints.news.downloadImage(newsId),
          method: 'GET',
          responseType: 'blob'
        })
        if (response.data) {
          const imageUrl = URL.createObjectURL(response.data)
          if (!didCancel) {
            setCurrentImage(imageUrl)
            onImageLoad()
          }
        }
      } catch (error) {
        console.error('Error creating object URL:', error)
      }
    }

    void createObjectURL()

    return () => {
      didCancel = true
      if (currentImage) {
        URL.revokeObjectURL(currentImage)
      }
    }
  }, [newsId])

  const onImageLoad = () => {
    setLoading(false)
  }

  return (
    <NewsModalWrapper>
      {imagePath ? (
        loading ? (
          <Spinner />
        ) : (
          <NewsImage image={currentImage} />
        )
      ) : (
        <NewsFallbackIcon className='fallback-icon' />
      )}

      <NewsContentSection
        title={title}
        content={content}
        availableNews={availableNews}
        activeNewsIndex={activeNewsIndex}
        handleTransition={handleTransition}
      />

      <ErrorDisplay error={markAsSeenError} />

      <NewsButtonsContainer
        activeNewsIndex={activeNewsIndex}
        availableNewsLength={availableNews.length}
        handleTransition={handleTransition}
      />
    </NewsModalWrapper>
  )
}
