import { useEffect } from 'react'
import { Fade } from 'react-awesome-reveal'
import { Link, match, useHistory, useRouteMatch } from 'react-router-dom'
import toast from 'react-hot-toast'

import { isValidObject } from 'mmfintech-commons'
import { useSearchVouchersQuery, paths, useBuyVoucherMutation, useBuyVouchersBulkMutation } from 'mmfintech-backend-api'

import { OldVoucherItem } from './oldVoucher/OldVoucherItem'
import { CommonPageTitle } from '../../components'
import { ActiveVouchersItem } from './activeVouchers/SingleActiveVoucher'
import { ErrorDisplay, Preloader, Button } from 'mmfintech-portal-commons'
import { CashVoucherWrapper, VoucherSuccessHeader } from './CashVoucher.styled'

import { CashVoucherBulkPortalResponse } from 'mmfintech-commons-types'

import SuccessIcon from '../../images/icons/payment-success-icon.svg?react'

const CashVoucherSuccess = () => {
  const routeMatch: match<{ uuid: string }> = useRouteMatch()
  const voucherUuid = routeMatch.params.uuid

  const [_, { data: buyVoucherData }] = useBuyVoucherMutation({
    fixedCacheKey: 'buy-voucher-mutation'
  })
  const [_buy, { data: buyBulkVoucherData }] = useBuyVouchersBulkMutation({
    fixedCacheKey: 'buy-voucher-bulk-mutation'
  })

  const responseData = buyVoucherData || buyBulkVoucherData

  const { voucher, error, isLoading, isFetching, isSuccess } = useSearchVouchersQuery(
    { uuids: [responseData?.refId || voucherUuid] },
    {
      skip: !isValidObject(responseData) && !voucherUuid,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data, ...rest }) => ({
        voucher: data?.content?.[0] || ({} as CashVoucherBulkPortalResponse),
        ...rest
      })
    }
  )
  const history = useHistory()
  useEffect(() => {
    if (error) {
      toast.error(<ErrorDisplay error={error} hideIcon />)
    }
  }, [error])

  useEffect(() => {
    if (isSuccess && !isValidObject(voucher)) {
      history.push(paths.more.cashVoucher('buy'))
    }
  }, [isSuccess])

  const handleClick = () => history.push(paths.more.cashVoucher('buy'))

  if (isLoading || isFetching) {
    return <Preloader />
  }
  return (
    <CashVoucherWrapper>
      <CommonPageTitle className='mt-4 mb-4'>New Voucher</CommonPageTitle>
      <VoucherSuccessHeader>
        <SuccessIcon />
        <h2 className='mt-0 mb-0'>You Have Successfully Purchased a Voucher</h2>
        <p>Here it is:</p>
      </VoucherSuccessHeader>
      <Fade className='full-width'>
        {voucher.status !== 'ACTIVE' ? <OldVoucherItem {...voucher} /> : <ActiveVouchersItem {...voucher} />}
      </Fade>

      <div className='mt-3'>
        <p>
          You can find your voucher in{' '}
          <Link className='my-vouchers-link' to={paths.more.cashVoucher('list')}>
            My Vouchers
          </Link>
        </p>
        <Button className='mt-2' text='Buy Another One' onClick={handleClick} />
      </div>
    </CashVoucherWrapper>
  )
}

export default CashVoucherSuccess
