import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import { TailSpin } from 'react-loader-spinner'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { RevealPinWrapper } from './SingleVoucher.styled'

import { useLazyRevealVoucherPinQuery } from 'mmfintech-backend-api'

import RevealPinIcon from '../../../images/active-voucher/reveal-pin.svg?react'

export const RevealPin = ({ voucherId }) => {
  const [shouldFlip, setShouldFlip] = useState(false)

  const [revealPin, { voucherPin, isError, error, isFetching }] = useLazyRevealVoucherPinQuery({
    selectFromResult: ({ data, ...rest }) => ({
      voucherPin: data?.securityCode || '',
      ...rest
    })
  })

  const handleClick = () => {
    if (!shouldFlip && !voucherPin) {
      revealPin({ uuid: voucherId })
      return
    }

    setShouldFlip(prev => !prev)
  }

  useEffect(() => {
    if (voucherPin && !shouldFlip) {
      setShouldFlip(true)
    }
  }, [voucherPin])

  useEffect(() => {
    if (isError) {
      toast.remove()
      if ('error' in error) {
        toast.error(error.error)
      } else {
        toast.error(<ErrorDisplay error={error} hideIcon />)
      }
    }
  }, [isError])

  return (
    <RevealPinWrapper
      onClick={handleClick}
      $shouldFlip={shouldFlip}
      data-test={shouldFlip ? 'revealed-pin' : 'hidden-pin'}>
      <div className='flip-card-inner'>
        <div className='flip-card-front'>
          <div>
            {isFetching ? (
              <TailSpin width={25} height={25} />
            ) : (
              <>
                <span>PIN</span>
                <span className='hidden-pin'>****</span>
              </>
            )}
          </div>
          <div>
            <RevealPinIcon />
          </div>
        </div>
        <div className='flip-card-back'>{voucherPin}</div>
      </div>
    </RevealPinWrapper>
  )
}
