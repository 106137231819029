import { useContext } from 'react'

import { Button } from 'mmfintech-portal-commons'
import { UnlockedFeatureModalWrapper } from './UnlockedFeatureModal.styled'

import { tr, GlobalContext } from 'mmfintech-commons'

import UnlockedDeposit from '../../../images/icons/unlocked-deposit.svg?react'
import UnlockedExchange from '../../../images/icons/unlocked-exchange.svg?react'

type UnlockedFeatureModalProps = {
  type: 'deposit' | 'exchange'
}

const modalType = {
  deposit: {
    title: tr('FRONTEND.HINTS.UNLOCKED_DEPOSITS.TITLE', 'Deposit'),
    body: tr(
      'FRONTEND.HINTS.UNLOCKED_DEPOSITS.BODY',
      'You can deposit in both fiat and crypto. Just follow through Deposit.'
    ),
    img: <UnlockedDeposit />
  },
  exchange: {
    title: tr('FRONTEND.HINTS.UNLOCKED_EXCHANGE.TITLE', 'Exchange'),
    body: tr(
      'FRONTEND.HINTS.UNLOCKED_EXCHANGE.BODY',
      'You can exchange your fiat or crypto holdings into other fiat currencies.'
    ),
    img: <UnlockedExchange />
  }
}

export const UnlockedFeatureModal = ({ type }: UnlockedFeatureModalProps) => {
  const { modalHide } = useContext(GlobalContext)

  if (!modalType[type]) {
    return null
  }

  const { title, body, img } = modalType[type] || {}

  return (
    <UnlockedFeatureModalWrapper>
      <div className='content'>
        {img}
        <div className='splitter'></div>
        <h3>{title}</h3>
        <p>{body}</p>
      </div>
      <Button text='Close' onClick={modalHide} />
    </UnlockedFeatureModalWrapper>
  )
}
