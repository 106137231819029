import React, { useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'

import { IDropDownMenuProps } from './Header.types'
import { DropDownMenuIcon, DropDownMenuWrapper, DropDownMenuContainer } from './Header.styled'

import { tr } from 'mmfintech-commons'
import { paths } from 'mmfintech-backend-api'

import ArrowDownIcon from '../../images/icons/arrow-down-white.svg?react'
import CryptoSavingIcon from '../../images/icons/crypto-saving.svg?react'
import FiatSavingIcon from '../../images/icons/fiat-saving.svg?react'
import KingdomCashIcon from '../../images/icons/kingdom-cash.svg?react'

export const SavingsMenu = ({ isOpen, setIsOpen }: IDropDownMenuProps) => {
  const menuRef = useRef<HTMLInputElement>(null)
  const { merchant } = useSelector(
    ({ user: { merchant } }: any) => ({
      merchant
    }),
    shallowEqual
  )

  const { capabilities } = merchant || {}
  const { enableCashVouchers } = capabilities || {}

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
    // eslint-disable-next-line
  }, [isOpen])

  const hideMenu = () => isOpen && setIsOpen(false)

  const handleOnClick = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    hideMenu()
  }

  return (
    <DropDownMenuWrapper ref={menuRef}>
      <DropDownMenuIcon className={`arrow-down ${isOpen ? 'active' : ''}`}>
        <ArrowDownIcon />
      </DropDownMenuIcon>

      {isOpen && (
        <DropDownMenuContainer>
          <NavLink to={paths.more.crypto()} className='menu-item selector'>
            <div onClick={handleOnClick}>
              <CryptoSavingIcon />
              {tr('FRONTEND.HEADER.CRYPTO_SAVINGS', 'Crypto Savings')}
            </div>
          </NavLink>
          <NavLink to={paths.more.fiat()} className='menu-item selector'>
            <div onClick={handleOnClick}>
              <FiatSavingIcon />
              {tr('FRONTEND.HEADER.FIAT_INVESTMENT', 'Money Savings')}
            </div>
          </NavLink>
          {enableCashVouchers ? (
            <NavLink className='menu-item selector' to={paths.more.cashVoucher('buy')}>
              <div onClick={handleOnClick}>
                <KingdomCashIcon />
                {tr('FRONTEND.HEADER.KINGDOM_CASH', 'Kingdom Cash')}
              </div>
            </NavLink>
          ) : null}
        </DropDownMenuContainer>
      )}
    </DropDownMenuWrapper>
  )
}
