import { useHistory } from 'react-router-dom'

import toast from 'react-hot-toast'

import { paths, useCreateReportMutation, useUpdateReportMutation } from 'mmfintech-backend-api'
import { Button, ErrorDisplay, Input, Preloader } from 'mmfintech-portal-commons'
import { ExpenseManagementReport } from 'mmfintech-commons-types'
import { fixDateOnly, tr, useFormValues } from 'mmfintech-commons'

import { toastOptions } from '../../../components'

import { ReportEditModalContainer, ReportEditModalHeader } from './report.styled'
import { GroupInputs } from '../expenses/styles/expenseModal.styled'

import CalendarIcon from '../../../images/icons/filter-calendar.svg?react'

type ReportEditModalProps = {
  report?: ExpenseManagementReport
  onClose: () => void
  reload?: () => void
}

export const ReportEditModal: React.FC<ReportEditModalProps> = ({ report, onClose, reload }) => {
  const history = useHistory()
  const [createReportRequest, { isLoading: createIsLoading, error: createError }] = useCreateReportMutation()
  const [editReportRequest, { isLoading: editIsLoading, error: editError }] = useUpdateReportMutation()
  const formValues = useFormValues({
    name: { required: true, validation: 'safe-string', value: report?.name },
    description: { validation: 'safe-string', value: report?.description },
    fromDate: { required: true, value: report?.fromDate },
    toDate: { required: true, value: report?.toDate }
  })

  const createReport = async e => {
    e.preventDefault()
    if (formValues.areValid()) {
      try {
        const dateInfo = {
          fromDate: new Date(fixDateOnly(formValues.getValue('fromDate'))),
          toDate: new Date(fixDateOnly(formValues.getValue('toDate')))
        }

        if (report?.id) {
          await editReportRequest({ reportData: { ...formValues.prepare(), ...dateInfo }, id: report?.id }).unwrap()
          toast.success(
            tr('FRONTEND.REPORTS.CREATE.EDITED_SUCCESSFULLY', 'Report edited successfully'),
            toastOptions.success
          )
          reload && reload()
          onClose()
        } else {
          const res: any = await createReportRequest({ ...formValues.prepare(), ...dateInfo }).unwrap()
          toast.success(
            tr('FRONTEND.REPORTS.CREATE.CREATED_SUCCESSFULLY', 'Report created successfully'),
            toastOptions.success
          )
          reload && reload()
          onClose()
          history.push(paths.expenseManagement.report(res?.data.id))
        }
      } catch (err) {
        formValues.handleErrors(err)
      }
    }
  }

  const buttonText =
    createIsLoading || editIsLoading ? (
      <Preloader />
    ) : report?.id ? (
      tr('FRONTEND.REPORTS.CREATE.SAVE', 'Save')
    ) : (
      tr('FRONTEND.REPORTS.CREATE.CREATE_REPORT', 'Create Report')
    )
  return (
    <ReportEditModalContainer>
      <ReportEditModalHeader>
        {report?.id
          ? tr('FRONTEND.REPORTS.CREATE.REPORT_DETAILS', 'Report Details')
          : tr('FRONTEND.REPORTS.CREATE.CREATE_NEW_REPORT', 'Create New Report')}{' '}
      </ReportEditModalHeader>
      <div className='form'>
        <Input
          type='text'
          {...formValues.registerInput('name')}
          label={tr('FRONTEND.REPORTS.CREATE.REPORT_NAME', 'Report Name')}
        />
        <Input
          type='text'
          {...formValues.registerInput('description')}
          label={tr('FRONTEND.REPORTS.CREATE.DESCRIPTION', 'Description')}
        />

        <GroupInputs>
          <Input
            type='date'
            {...formValues.registerInput('fromDate')}
            label={tr('FRONTEND.REPORTS.CREATE.FROM', 'From')}
            icon={<CalendarIcon />}
            maxDate={formValues.getValue('toDate')}
            parentClassName='calendar-input'
            dateFormat='MM/dd/yyyy'
          />
          <Input
            type='date'
            {...formValues.registerInput('toDate')}
            label={tr('FRONTEND.REPORTS.CREATE.TO', 'To')}
            icon={<CalendarIcon />}
            minDate={formValues.getValue('fromDate')}
            parentClassName='calendar-input'
            dateFormat='MM/dd/yyyy'
          />
        </GroupInputs>
      </div>
      <ErrorDisplay error={createError || editError} />
      <div className='buttons-container'>
        <Button color='primary' data-test='create-edit-report' text={buttonText} onClick={createReport} />
        <Button
          color='secondary'
          data-test='close-edit-report'
          text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
          onClick={onClose}
        />
      </div>
    </ReportEditModalContainer>
  )
}
