import { CustomToasterWrapper } from './CustomToaster.styled'
import { toast, Toaster, ToastBar } from 'react-hot-toast'

import CloseIcon from '../../images/icons/toaster-close.svg?react'

export const CustomToaster = () => (
  <Toaster
    gutter={10}
    position='top-right'
    containerStyle={{
      top: '10rem',
      zIndex: '10010'
    }}>
    {t => (
      <ToastBar
        toast={t}
        style={{
          display: 'block',
          backgroundColor: '#4B4B4B',
          borderRadius: '4px',
          color: '#ffffff',
          fontFamily: 'inherit',
          fontSize: '1.2rem',
          lineHeight: ' 2rem',
          fontStyle: 'normal',
          padding: '1.2rem',
          width: '100%',
          maxWidth: '320px'
        }}>
        {({ icon, message }) => (
          <CustomToasterWrapper>
            <div className='icon'>{icon}</div>
            <div className='content'>
              <span className={`label ${t.type}`}>{t.type === 'success' ? 'Successful' : 'Error'}</span>
              <span>{message}</span>
            </div>
            {t.type !== 'loading' && <CloseIcon className='close-icon' onClick={() => toast.dismiss(t.id)} />}
          </CustomToasterWrapper>
        )}
      </ToastBar>
    )}
  </Toaster>
)
