import { useEffect, useState } from 'react'
import { UploadedFileWrapper } from '../styled/conversationModal.styled'
import FileComponent from './FileComponent'

import RemoveIcon from '../../../../images/icons/x-mark.svg?react'

const UploadedFile = ({ file, onFileRemove }: { file: File; onFileRemove: () => void }) => {
  const [imgPreview, setImagePreview] = useState('')
  useEffect(() => {
    if (!file) {
      setImagePreview('')
      return
    }
    const objectUrl = URL.createObjectURL(file)
    setImagePreview(objectUrl)

    return () => URL.revokeObjectURL(objectUrl)
  }, [file])

  return (
    <UploadedFileWrapper>
      {file.type.includes('image') ? (
        <img src={imgPreview} alt={file?.name} />
      ) : (
        <FileComponent name={file.name} iconStroke='#0061E8' />
      )}
      <RemoveIcon onClick={onFileRemove} className='remove-icon' />
    </UploadedFileWrapper>
  )
}

export default UploadedFile
