import { useContext, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { actions } from 'mmfintech-backend-api'

import { GlobalContext, useFormValues, tr, isValidArray } from 'mmfintech-commons'

import { Button, Preloader, FileInput, ErrorDisplay, Input } from 'mmfintech-portal-commons'

import ConversationConfirm from './elements/ConversationConfirm'
import ConversationSuccessModal from './elements/ConversationSuccessModal'
import ConversationLine from './elements/ConversationLine'
import UploadedFile from './elements/UploadedFile'
import { ConversationModalWrapper } from './styled/conversationModal.styled'

import ConversationUploadIcon from '../../../images/icons/conversation-upload-icon.svg?react'

const ConversationModal = ({ threadId, type }: { threadId: number; type: string }) => {
  const { modalHide, modalShow }: any = useContext(GlobalContext)
  const [showConfirm, setShowConfirm] = useState(false)
  const { conversation, conversationFetching, conversationError, sendMessageFetching, sendMessageError } = useSelector(
    ({
      user: { conversation, conversationFetching, conversationError, sendMessageFetching, sendMessageError }
    }: any) => ({
      conversation,
      conversationFetching,
      conversationError,
      sendMessageFetching,
      sendMessageError
    }),
    shallowEqual
  )
  const dispatch = useDispatch()
  const { setValue, getValue, registerInput, prepare, areValid, handleErrors } = useFormValues({
    files: {},
    message: { required: true /*, validation: 'safe-string'*/ }
  })

  useEffect(() => {
    dispatch(actions.merchant.loadConversationMessages(threadId))
    //eslint-disable-next-line
  }, [dispatch])

  const handleSendMessage = () => {
    setShowConfirm(false)
    if (areValid()) {
      dispatch(
        actions.merchant.sendMessage(threadId, prepare(), () => {
          modalShow({
            options: {
              closeOnClickOutside: true,
              size: 'auto',
              overflow: 'none',
              onClose: () => dispatch(actions.merchant.loadAllConversations())
            },
            content: (
              <ConversationSuccessModal
                modalHide={() => {
                  dispatch(actions.merchant.loadAllConversations())
                  modalHide()
                }}
              />
            )
          })
        })
      )
    }
  }

  const handleChange = (list: File[]) => {
    if (getValue('files').length < 3) {
      if (isValidArray(list)) {
        const updatedFiles = [...Array.from(getValue('files')), ...Array.from(list)]
        setValue('files', updatedFiles)
      }
    }
    return false
  }

  const handleRemoveFile = (index: number) => {
    const updatedFiles = [...getValue('files')]
    updatedFiles.splice(index, 1)
    setValue('files', updatedFiles)
  }

  useEffect(() => {
    handleErrors(sendMessageError)
    //eslint-disable-next-line
  }, [sendMessageError])

  return (
    <ConversationModalWrapper>
      <div className='header'>{tr(`FRONTEND.CONVERSATION.BANNER.${type}.TITLE`, type)}</div>
      <div className='conversation-pane'>
        {conversationFetching ? (
          <Preloader />
        ) : (
          isValidArray(conversation) &&
          conversation.map(
            (
              conversationLine: { isAdminMessage: boolean; content: string; type: 'TEXT' | 'DOCUMENT' },
              index: number
            ) => (
              <ConversationLine
                key={index}
                type={conversationLine?.type}
                content={conversationLine?.content}
                isAdminMessage={conversationLine?.isAdminMessage}
              />
            )
          )
        )}
      </div>
      <div className='input-pane'>
        <div className='input-section'>
          <FileInput maxNumber={3} onChange={handleChange} acceptType={['jpg', 'png', 'webp', 'jpeg', 'pdf']}>
            {({ onFileUpload }: any) => (
              <div className='upload-section'>
                <div className='files'>
                  {isValidArray(getValue('files')) &&
                    getValue('files').map((file: File, index: number) => (
                      <UploadedFile key={index} file={file} onFileRemove={() => handleRemoveFile(index)} />
                    ))}
                </div>
                <ConversationUploadIcon style={{ cursor: 'pointer' }} onClick={onFileUpload} />
              </div>
            )}
          </FileInput>
          <Input
            className='message-input'
            type='textarea'
            {...registerInput('message')}
            data-test='message-input'
            placeholder='Type your answer here'
          />
        </div>
        <ErrorDisplay error={[sendMessageError, conversationError]} />
        <div className='buttons-container'>
          <Button
            color='secondary'
            text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            onClick={modalHide}
            data-test='button-cancel'
          />
          <Button
            color='primary'
            text={tr('FRONTEND.CONVERSATION.MODAL.SUBMIT.BUTTON', 'Submit')}
            onClick={() => {
              if (areValid()) {
                setShowConfirm(true)
              }
            }}
            data-test='button-submit-message'
            loading={sendMessageFetching}
          />
        </div>
      </div>
      {showConfirm && (
        <ConversationConfirm handleConfirm={handleSendMessage} hideConfirmation={() => setShowConfirm(false)} />
      )}
    </ConversationModalWrapper>
  )
}

export default ConversationModal
