import { BuyVoucherInstructionsItem, BuyVoucherInstructionsWrapper } from './BuyVouchersTab.styled'

import ExpiryIcon from '../../../images/purchase-voucher/expiry-icon.svg?react'
import WalletIcon from '../../../images/purchase-voucher/instructions-proceed-icon.svg?react'

export const BuyVoucherInstructions = () => {
  return (
    <BuyVoucherInstructionsWrapper>
      <BuyVoucherInstructionsItem>
        <div>
          <WalletIcon />
        </div>
        <div>
          When you complete the voucher purchase, it will be immediately visible in the My Vouchers section and
          available for your use.
        </div>
      </BuyVoucherInstructionsItem>
      <BuyVoucherInstructionsItem>
        <div>
          <ExpiryIcon />
        </div>
        <div>Once purchased vouchers have 1 year until expiring.</div>
      </BuyVoucherInstructionsItem>
    </BuyVoucherInstructionsWrapper>
  )
}
