import { useHistory } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'

import parse from 'html-react-parser'
import styled from 'styled-components'

import { ErrorDisplay } from 'mmfintech-portal-commons'

import { redirectToExternalUrl, tr } from 'mmfintech-commons'
import { paths, useDepositInitiateMutation, useDepositPreviewMutation } from 'mmfintech-backend-api'

import { PaymentMethod, PaymentMethodEnum } from 'mmfintech-commons-types'

import applicationFeeImage from '../../../images/app-fee-no-funds-image.png'
import btcIcon from '../../../images/icons/btc-icon.png'

import BankWebServiceIcon from '../../../images/icons/bank-web-service-icon.svg?react'
import PaymentNetworkIcon from '../../../images/icons/payment-network-icon.svg?react'
import ReferenceIcon from '../../../images/icons/reference-icon.svg?react'
import TransferDetailsIcon from '../../../images/icons/transfer-details-icon.svg?react'
import SepaIcon from '../../../images/icons/sepa-icon.svg?react'
import SwiftIcon from '../../../images/icons/swift-icon.svg?react'
import ArrowIcon from '../../../images/icons/arrow-right-small-grey.svg?react'

interface ApplicationFeeDetailsProps {
  amount: number
  currency: string
}

const ApplicationFeeDetailsModal = ({ amount, currency }: ApplicationFeeDetailsProps) => {
  const { merchant } = useSelector(
    ({ banking: { depositError, depositPreviewError }, user: { merchant } }: any) => ({
      merchant,
      depositError,
      depositPreviewError
    }),
    shallowEqual
  )
  const { entityType, capabilities } = merchant || {}
  const { prospectCryptoExchangeEnabled } = capabilities || {}

  const history = useHistory()

  const [depositPreview, { error: previewError, reset: resetPreview }] = useDepositPreviewMutation({
    fixedCacheKey: 'deposit-preview'
  })
  const [depositInitiate, { error: initiateError, reset: resetInitiate }] = useDepositInitiateMutation({
    fixedCacheKey: 'deposit-initiate'
  })

  const displayCryptoInstructions = (paymentMethod: PaymentMethod, data: any) => {
    const { result, resultType } = data || {}
    if (resultType === 'REDIRECT_URL') {
      // if result type is redirect -- redirect immediately
      if (typeof result === 'string') {
        redirectToExternalUrl(result)
      } else {
        const { redirectUrl } = result || {}
        redirectToExternalUrl(redirectUrl)
      }
    } else {
      history.push({
        pathname: paths.banking.depositPreview(),
        state: { paymentType: paymentMethod }
      })
    }
  }

  const handleDepositPreview = async (paymentMethod: PaymentMethod) => {
    try {
      await depositPreview({ amount, currency, paymentMethod }).unwrap()
      void handleDepositInitiate(paymentMethod)
    } catch (_error) {}
  }

  const handleDepositInitiate = async (paymentMethod: PaymentMethod) => {
    try {
      const response = await depositInitiate({ amount, currency, paymentMethod }).unwrap()
      displayCryptoInstructions(paymentMethod, response)
    } catch (_error) {}
  }

  const handleSelectPaymentOption = (paymentMethod: PaymentMethod) => {
    if (merchant && currency && entityType !== 'INDIVIDUAL') {
      if (paymentMethod === PaymentMethodEnum.CRYPTO) {
        resetPreview()
        resetInitiate()
        displayCryptoInstructions(paymentMethod, null)
      } else {
        void handleDepositPreview(paymentMethod)
      }
    }
  }

  return (
    <ApplicationFeeDetailsWrapper>
      <img src={applicationFeeImage} alt='application-fee' />
      <div className='title'>
        {tr(
          'FRONTEND.APPLICATION_FEE.DETAILS.TITLE',
          `The Kingdom Bank can’t charge your bank or crypto wallet automatically`
        )}
      </div>
      <div className='subtitle'>
        {tr(
          'FRONTEND.APPLICATION_FEE.DETAILS.SUBTITLE',
          'You’ll need to do this bit yourself to pay by bank transfer or crypto'
        )}
      </div>

      {paymentSteps().map(({ icon, text }, i) => (
        <PaymentStep key={i}>
          {icon}
          <div>{parse(text)}</div>
        </PaymentStep>
      ))}

      <ErrorDisplay
        error={
          previewError || initiateError
            ? {
                ...previewError,
                ...initiateError,
                error:
                  'Contact your account manager for more information on how to complete the application fee payment.',
                errorKey: 'MERCHANTS.ERROR.PAYMENT_METHOD_UNAVAILABLE'
              }
            : null
        }
      />

      <div className='buttons-container'>
        <PaymentMethodButton
          onClick={() => handleSelectPaymentOption(PaymentMethodEnum.SWIFT_BANKWIRE)}
          data-test='button-swift'>
          <div className='left'>
            <SwiftIcon />
            <span>SWIFT</span>
          </div>
          <div>
            <ArrowIcon className='arrow' />
          </div>
        </PaymentMethodButton>

        <PaymentMethodButton
          onClick={() => handleSelectPaymentOption(PaymentMethodEnum.BANKWIRE)}
          data-test='button-sepa'>
          <div className='left'>
            <SepaIcon />
            <span>SEPA</span>
          </div>
          <div>
            <ArrowIcon className='arrow' />
          </div>
        </PaymentMethodButton>

        {prospectCryptoExchangeEnabled && (
          <PaymentMethodButton
            onClick={() => handleSelectPaymentOption(PaymentMethodEnum.CRYPTO)}
            data-test='button-crypto'>
            <div className='left'>
              <img src={btcIcon} alt='btc-icon' />
              <span>Crypto</span>
            </div>
            <div>
              <ArrowIcon className='arrow' />
            </div>
          </PaymentMethodButton>
        )}
      </div>
    </ApplicationFeeDetailsWrapper>
  )
}

export default ApplicationFeeDetailsModal

const paymentSteps = () => [
  {
    icon: <PaymentNetworkIcon />,
    text: tr(
      'FRONTEND.APPLICATION_FEE.DETAILS.STEP_1',
      'Choose the payment network of your preference - <b>SWIFT</b>, <b>SEPA</b> or <b>Crypto</b>'
    )
  },
  {
    icon: <TransferDetailsIcon />,
    text: tr('FRONTEND.APPLICATION_FEE.DETAILS.STEP_2', 'Our bank/crypto transfer details will appear on the screen')
  },
  {
    icon: <BankWebServiceIcon />,
    text: tr(
      'FRONTEND.APPLICATION_FEE.DETAILS.STEP_3',
      'Log into your crypto wallet or bank’s web service (you can also go to one of your bank’s branches) to transfer the funds'
    )
  },
  {
    icon: <ReferenceIcon />,
    text: tr(
      'FRONTEND.APPLICATION_FEE.DETAILS.STEP_4',
      'Don’t forget to add the <b>reference</b>. It will help us process your deposit faster'
    )
  }
]

const PaymentMethodButton = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;

  border: 1px solid rgba(133, 160, 173, 0.3);
  border-radius: 4px;

  flex: 1;
  padding: 1rem;
  height: 50px;

  cursor: pointer;

  .left {
    font-size: 1.4rem;
    line-height: 1.7rem;

    display: flex;
    align-items: center;
    gap: 1.2rem;
  }
  .arrow {
    width: 1rem;
  }
`
const PaymentStep = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;

  width: 100%;

  font-size: 1.4rem;
  line-height: 1.7rem;
`

const ApplicationFeeDetailsWrapper = styled.div`
  width: 92vw;
  max-width: 50rem;

  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

  padding: 3rem 2rem;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 3rem;

  @media (min-width: 940px) {
    padding: 4rem 5rem;
  }

  .title {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.9rem;
    text-align: center;
  }
  .subtitle {
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: #85a0ad;
  }
  .buttons-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    width: 100%;
    gap: 2rem;

    @media (min-width: 480px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
`
