import { useContext, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { actions } from 'mmfintech-backend-api'

import { GlobalContext, tr, isValidArray } from 'mmfintech-commons'

import ConversationModal from './ConversationModal'
import { ConversationItem, ConversationsBannerWrapper } from './styled/conversationBanner.styled'

import ArrowIcon from '../../../images/icons/arrow-right-red.svg?react'
import AttentionIcon from '../../../images/icons/attention-icon.svg?react'

interface Conversation {
  id: number
  merchantId: number
  status: string
  brand: string
  type: string
}

const ConversationsBanner = () => {
  const { conversationThreads } = useSelector(
    ({ user: { conversationThreads } }: any) => ({
      conversationThreads
    }),
    shallowEqual
  )
  const filteredConversations =
    isValidArray(conversationThreads) &&
    conversationThreads.filter((conversation: Conversation) => conversation?.status === 'WAITING_CLIENT')

  const { modalShow }: any = useContext(GlobalContext)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.merchant.loadAllConversations())
    // eslint-disable-next-line
  }, [dispatch])

  const handleOpenThread = (threadId: number, type: string) => {
    modalShow({
      options: { closeOnClickOutside: true, size: 'auto', overflow: 'auto' },
      content: <ConversationModal threadId={threadId} type={type} />
    })
  }

  return isValidArray(filteredConversations) ? (
    <ConversationsBannerWrapper>
      <div className='attention-container'>
        <AttentionIcon />
        <div className='attention-title'>{tr('FRONTEND.CONVERSATION.BANNER.TITLE', 'Action Required')}</div>
      </div>
      <div className='items-container'>
        {filteredConversations.map(({ id, type }: { id: number; type: string }) => (
          <ConversationItem key={id} onClick={() => handleOpenThread(id, type)}>
            <div>{tr(`FRONTEND.CONVERSATION.BANNER.${type}.TITLE`, type)}</div>
            <ArrowIcon />
          </ConversationItem>
        ))}
      </div>
    </ConversationsBannerWrapper>
  ) : null
}

export default ConversationsBanner
