import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import moment from 'moment/moment'

import { ActivityWrapper } from './ActivitiesMenu.styled'
import { actions, paths, transformMerchantActivity, useActivities } from 'mmfintech-backend-api'

import { IActivityComponentProps } from './ActivitiesMenu.types'

import DepositIcon from '../../images/icons/deposit-icon.svg?react'
import SendIcon from '../../images/icons/send-icon.svg?react'
import ExchangeIcon from '../../images/icons/exchange-icon.svg?react'
import WithdrawalIcon from '../../images/icons/withdrawal-icon.svg?react'
import RecipientIcon from '../../images/icons/recipient-icon.svg?react'

export const ActivityComponent = ({ activity, onClose }: IActivityComponentProps) => {
  const dispatch = useDispatch()
  const { handleActivityClick } = useActivities({
    securityPath: paths.security(),
    handleInvoicePreview: (invoiceId: number) => {
      dispatch(actions.invoice.findById(invoiceId))
      dispatch(actions.invoice.fetchInvoicePreview(invoiceId))
    }
  })

  const { type, text: activityText, time } = activity

  const text = useMemo(() => {
    return activityText.replace(/<.*?>/g, '')
  }, [activityText])

  const data = transformMerchantActivity(activity)

  return (
    <ActivityWrapper
      onClick={() => {
        onClose()
        handleActivityClick(data)
      }}>
      <div className='inner'>
        <div className='left'>
          <div>{activityIcons[type]}</div>
          <div className='activity-info'>
            <div className='activity-title'>{text}</div>
            <div className='timestamp'>{moment(time).format('HH:MM')}</div>
          </div>
        </div>
      </div>
    </ActivityWrapper>
  )
}

const activityIcons = {
  ACCOUNT_APPROVED: <RecipientIcon />,
  CONTACT_CREATED: <RecipientIcon />,
  CUSTOMER_CREATED: <RecipientIcon />,
  DEPOSIT_PENDING: <DepositIcon />,
  DEPOSIT_PROCESSED: <DepositIcon />,
  DEPOSIT_REJECTED: <DepositIcon />,
  DEPOSIT_RETURNED: <DepositIcon />,
  ENOTAS_INVOICE_APPROVED: <RecipientIcon />,
  ENOTAS_INVOICE_CANCELED: <RecipientIcon />,
  ENOTAS_INVOICE_DECLINED: <RecipientIcon />,
  EXCHANGE_PROCESSED: <ExchangeIcon />,
  INVOICE_CREATED: <RecipientIcon />,
  INVOICE_OPENED: <RecipientIcon />,
  INVOICE_PAID: <RecipientIcon />,
  INVOICE_PARTIALLY_PAID: <RecipientIcon />,
  ISSUE_IBAN_DECLINED: <RecipientIcon />,
  ISSUE_IBAN_FAILED: <RecipientIcon />,
  ISSUE_IBAN_INITIATED: <RecipientIcon />,
  ISSUE_IBAN_ISSUED: <RecipientIcon />,
  REFUND_PENDING: <RecipientIcon />,
  REFUND_PROCESSED: <RecipientIcon />,
  REFUND_REJECTED: <RecipientIcon />,
  REGISTERED_ACCOUNT: <RecipientIcon />,
  TRANSFER_RECEIVED: <SendIcon />,
  TRANSFER_SENT: <SendIcon />,
  WITHDRAWAL_PENDING: <WithdrawalIcon />,
  WITHDRAWAL_PROCESSED: <WithdrawalIcon />,
  WITHDRAWAL_REJECTED: <WithdrawalIcon />,
  WITHDRAWAL_REVERTED: <WithdrawalIcon />,
  WALLET_PAYMENT_COMPLETED: <SendIcon />
}
