import { useState } from 'react'
import { ModalDialog } from '../../../components'

import { Button } from 'mmfintech-portal-commons'
import { tr } from 'mmfintech-commons'

import CounterpartyCreateModal from './CounterpartyCreateModal'

import { HeaderContainer, VerticallyCentered } from '../../reports/ReportsTableHeaderStyled'

import AddIcon from '../../../images/icons/deposit.svg?react'

export const CounterpartyViewHeader = () => {
  const [isCreateModalVisible, setIsCreateModalVisible] = useState<boolean>(false)

  const handleCreateModalOpen = () => setIsCreateModalVisible(true)
  const handleCreateModalClose = () => setIsCreateModalVisible(false)

  return (
    <HeaderContainer>
      <div className='left-wrapper'></div>
      <div className='right-wrapper-counterparty'>
        <VerticallyCentered>
          <Button
            color='round-primary'
            className='sticky'
            text={tr('FRONTEND.EXPENSE_MANAGEMENT.CREATE_NEW', 'Create new')}
            icon={<AddIcon />}
            data-test='button-add-counterparty'
            onClick={handleCreateModalOpen}
          />
          <ModalDialog
            options={{ size: 'auto', closeOnEscape: false, closeOnClickOutside: false }}
            visible={isCreateModalVisible}
            content={<CounterpartyCreateModal onClose={handleCreateModalClose} />}
            onClose={handleCreateModalClose}
          />
        </VerticallyCentered>
      </div>
    </HeaderContainer>
  )
}
